import type { FeatureFlag } from '@/types/enums'

interface IManagementFlag {
  [FeatureFlag.EnableCardAuth]: boolean
  [FeatureFlag.EnableHealthPlan]: boolean
  [FeatureFlag.MinValueToShowWppButton]: number
  [FeatureFlag.BlockWithoutPrescription]: boolean
}

export default () => useLazyFetch<IManagementFlag>('/api/management-flag', {
  default: () => ({}) as IManagementFlag,
  dedupe: 'defer',
})
